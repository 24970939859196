import { GetStaticProps } from "next";

import serverSideTranslations from "lib/i18n/_pagesRouterCompat/serverSideTranslations";

import Error from "./_error";

/* 
Next.js forces 404 to be static so we need to load brand info client-side. See:
	- https://github.com/vercel/next.js/discussions/11945
We read the content from /_error-props endpoint to lazily load the 404 static page.
*/
const Error404: React.FC = () => {
  return <Error statusCode={404} />;
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations("/404" || "", locale || "en")),
    },
  };
};

export default Error404;
