import { captureException, flush as sentryFlush } from "@sentry/nextjs";
import { NextPage } from "next";
import { useEffect, useState } from "react";

import { Provider as BrandProvider } from "components/Context/BrandContext";
import ErrorFull from "components/ErrorFull/ErrorFull";
import Layout from "components/Layout/Layout";
import { HttpError } from "lib/client/bff";
import { log, logHttpRequest, LogSeverity } from "lib/logger";
import { Brand, Content, IncomingMessageWithContext } from "types";

const ErrorPage: NextPage<ErrorPageProps> = ({
  statusCode,
  errorCode,
  hasGetInitialPropsRun,
  err,
}) => {
  const [response, setResponse] = useState<{
    brand: Brand;
    content: Content;
  } | null>(null);
  useEffect(() => {
    const locale =
      typeof window != "undefined"
        ? window.location.pathname.match(`^/(..)/`)
        : "en";
    fetch(`/_error-props?locale=${locale ? locale[1] : "en"}`)
      .then((response) => response.json())
      .then((data) => setResponse(data));
    if (!hasGetInitialPropsRun && err) {
      captureException(err);
    }
  }, []);

  if (response === null) {
    return null;
  }

  return (
    <BrandProvider brand={response.brand}>
      <Layout brand={response.brand} content={response.content}>
        <ErrorFull errorCode={errorCode} statusCode={statusCode} />
      </Layout>
    </BrandProvider>
  );
};

// We need to use getInitialProps here. Next-translate does not support using
// serverSideProps together with static 404.tsx.
// We should recover serverSideProps and withDirectChannelCommonProps once
// https://github.com/vinissimus/next-translate/issues/491 is fixed
ErrorPage.getInitialProps = async ({ req, res, err, asPath }) => {
  if (!res) {
    log(
      err?.stack ||
        err?.message ||
        "Something went wrong but no response found",
      LogSeverity.Error,
      req as IncomingMessageWithContext,
    );
  }

  await logError(err, asPath);

  if (res && err?.statusCode) {
    res.statusCode = err.statusCode;
    logHttpRequest(req as IncomingMessageWithContext, res, err || undefined);
  }

  return {
    err,
    statusCode: err?.statusCode ?? res?.statusCode ?? 500,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorCode: (err as any)?.errorCode,
    // Workaround for https://github.com/vercel/next.js/issues/8592
    hasGetInitialPropsRun: true,
    trackingContext: {
      page_type: "error",
    },
  };
};

const logError = async (
  err?:
    | (Error & {
        statusCode?: number;
      })
    | null,
  asPath?: string,
) => {
  if (!err) {
    captureException(
      new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
    );
  } else if (err instanceof HttpError) {
    captureException(
      new Error(
        `Unhandled Gateway error response (${err.statusCode}) at ${asPath}: ${err.errorMessage}`,
      ),
    );
  } else if ("message" in err) {
    captureException(new Error(`Unhandled error: ${err.message}`));
  } else {
    captureException(err);
  }

  await sentryFlush(2000);
};

type ErrorPageProps = {
  err?:
    | (Error & {
        statusCode?: number;
      })
    | null;
  statusCode: number;
  errorCode?: string;
  hasGetInitialPropsRun?: boolean;
};

export default ErrorPage;
