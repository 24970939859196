import { Button } from "@liberetech/design-system";
import classNames from "classnames";

import { useTranslation } from "lib/i18n/client";

import styles from "./ErrorFull.module.css";

const ErrorFull: React.FC<ErrorFullProps> = ({ errorCode, statusCode }) => {
  const { t } = useTranslation("errors");
  const errorMessageKey = messageKeyForError(statusCode, errorCode);
  return (
    <div className={classNames("grid", styles.content)}>
      <img className={styles.image} src="/static/images/404.svg" />
      <div className={styles.message}>
        <h1>
          {statusCode} - {t(`${errorMessageKey}.title`)}
        </h1>
        <p>{t(`${errorMessageKey}.description`)}</p>
        <Button className={styles.backButton} href="/">
          {t("back")}
        </Button>
      </div>
    </div>
  );
};

const knownErrorCodes = ["no_pending_balance"];
const knownStatusCodes = [401, 404, 500, 501];

const messageKeyForError = (statusCode?: number, errorCode?: string) => {
  statusCode = statusCode || 500;
  if (errorCode && knownErrorCodes.includes(errorCode)) {
    return `custom.${errorCode}`;
  }

  if (knownStatusCodes.includes(statusCode)) {
    return `status.${statusCode}`;
  }

  return `status.500`;
};

type ErrorFullProps = { errorCode?: string; statusCode: number };

export default ErrorFull;
